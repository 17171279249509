/**
 * Signup Page
 * @module
 * @see module:views/baseView
 * @requires module:views/baseView
 */
"use strict";
/* global FB, App, grecaptcha */
var Handlebars = require('handlebars');
var HandlebarsHelper = require('js/helpers/handlebarsHelpers');

var $ = require('jquery'),
    _ = require('underscore'),

    template = Handlebars.compile(require('./../../templates/pageSignup.hbs').default),
    templateFbSignup = Handlebars.compile(require('./../../templates/fbSignup.hbs').default),
    Backbone = require('backbone'),
    BaseView = require('./baseView'),
    analytics = require('./../helpers/analytics'),
    UserModel = require('./../models/userModel.js'),
    config = require('./../models/configModel'),
    urlHelpers = require('./../helpers/urlHelpers');

var MobileDetect = require('mobile-detect'),
    md = new MobileDetect(window.navigator.userAgent);

Backbone.$ = $;
/** @global */
window.jQuery = $;
var mailCheckObj = require('./../helpers/kitomba_mailcheck');

require('jquery.cookie');

require('jquery-validation');
module.exports = BaseView.extend({
    page: 'signup',
    pageTitle: 'Log in or sign up to continue',
    pageTitleFB: 'Facebook sign up',
    template: template,
    templateFb: templateFbSignup,
    events: {
        'click #signupToggle, #signupLink': 'signUpAction',
        'click #loginToggle': 'loginAction',
        'click #emailSignupTrigger': 'signUpWithEmailAction',
        'click .signUpByEmail': 'signUpWithEmailAction',
        'click #btnContinue': 'signUpAndContinueAction',
        'keyup #password_signup': 'enterSubmitAction',
        'blur #signup_email': 'doMailcheck',
        'click .email-suggestion': 'chooseDomainSuggestion',
        "click #loginInstead": 'loginInstead',

        'click #loginBtn.btn-success': 'clickLoginBtn',
        'keyup #loginForm input': 'checkLoginEnterKeyAction',
        'keyup #forgotPassForm input': 'checkForgotEnterKeyAction',
        'click #sendPwReminder.btn-success': 'resetPasswordAction',
        'show.bs.modal #loginModal': 'loginModalShowEvent',
        'show.bs.modal #forgotModal': 'pwModalShowEvent',

        'click #wall_facebook_login.btn-facebook': 'loginFacebookAction',
        'click #wall_facebook_signup.btn-facebook': 'loginFacebookAction',

        'blur #raw_phone': 'doPhoneValidation',
        'keyup #raw_phone.warning, #raw_phone.checked': 'doPhoneValidation'
    },
    initialize: function (options) {
        //call parent constructor
        this.options = options;
        this.businessModel = options.businessModel;
        this.userModel = options.userModel;
        this.signup = options.signup;
        BaseView.prototype.initialize(options);
        $.cookie('codeFailed', false);

        /*$.validator.addMethod("valid_number", function(value, element) {
         return this.optional(element) ||
         value.match(/^([0-9,\+-]|\s)+$/);
         }, "Please enter a valid mobile number");*/

        _.bindAll(this, 'renderFacebook','resumeFacebookLogin', 'signUpAndContinueAction','startFacebookLogin');
    },
    initValidator: function () {
        $.validator.addMethod('valid_email', function (value, element, regexp) {
            var re = new RegExp(regexp);
            return re.test(value);
        }, "Please enter a valid email address.");
        $.validator.addMethod('valid_phone', function (value, element, regexp) {
            var re = new RegExp(regexp);
            value = value.trim();
            return re.test(value);
        }, "Please enter a valid phone number");
        $('#formSignup').validate({
            //  onfocusout: false,
            //  onkeyup: false,
            //  onclick: false,
            rules: {
                first_name: {
                    required: true
                },
                last_name: {
                    required: true
                },
                email: {
                    required: true,
                    email: true,
                    valid_email: /^(([^<>()[\]\.,\\'`~#$%^&*;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
                },
                raw_phone: {
                    required: true,
                    minlength: 6,
                    valid_phone: /^\s*\+*(?:[(0-9-.,)]\s?){4,20}[0-9]\s*$/
                },
                password: {
                    required: true,
                    minlength: 8
                },
                confirm_password: {
                    required: true,
                    minlength: 8
                },
                terms: {
                    required: true
                }
            },
            messages: {
                first_name: {
                    required: "First name is required"
                },
                last_name: {
                    required: "Last name is required"
                },
                email: {
                    required: "Please enter a valid email address",
                    email: "Please enter a valid email address",
                    valid_email: "Please enter a valid email address"
                },
                raw_phone: {
                    required: "Please enter a valid mobile number",
                    minlength: "Please enter a valid mobile number"
                },
                password: {
                    required: "Password is required",
                    minlength: "Password must be at least 8 characters"
                },
                confirm_password: {
                    required: "Password is required",
                    minlength: "Password must be at least 8 characters"
                },
                terms: {
                    required: "You must accept the terms and conditions to sign up"
                }
            },
            errorLabelContainer: "#signupValidationAnchor",
            wrapper: 'li',
            errorPlacement: function (error, element) {
                $('#signupValidationAnchor').append(error);
            }
        });
    },
    render: function (e, action, method) {
        //var data = this.mergeTemplateData();
        var businessModel_name = this.options.businessModel.get('name');
        this.$el.html(this.template({name: businessModel_name}));
        BaseView.prototype.render(this);
        if (action === 'signup') {
            this.signUpAction(e);
        } else {
            this.loginAction(e);
        }
        this.setPageTitle(this.pageTitle);

        $('[data-toggle="tooltip"]').tooltip();

        if (method === 'email') {
            this.signUpWithEmailAction(e);
        }
    },
    renderFacebook: function () {
        this.$el.html(this.templateFb({
            name: this.options.businessModel.get('name'),
            userModel: this.userModel.toJSON()
        }));
        BaseView.prototype.render(this);

        this.initValidator();

        this.setPageTitle(this.pageTitleFB);

        $('[data-toggle="tooltip"]').tooltip();
    },
    signUpAction: function (e) {
        analytics.trackGAEvent(false,'Account','Clicked Sign up');
        this.loginTransition(e, true, 'renderSignup');
        //$('#who_login').addClass("flip");
    },
    loginAction: function (e) {
        this.loginTransition(e, false, 'renderLogin');

        if (!md.mobile()) {
            setTimeout(function () {
                $('#email').focus();
            }, 200);
        }

        //$('#who_login').removeClass("flip");
    },

    loginTransition: function (e, removeClass, action) {
        if (e != null) {
            e.preventDefault();
        }
        if (removeClass) {
            $('#who_login').addClass("flip");
            this.initValidator();
        } else {
            $('#signupOptions').slideDown();
            $('#signupForm').slideUp();

            $('#who_login').removeClass("flip");
        }
        if (e != null) {
            $(e.currentTarget).one('webkitAnimationEnd oanimationend msAnimationEnd animationend',
                function (e) {
                    $('body').on('route:navigate',{trigger: true});
                });
        }
    },
    signUpWithEmailAction: function (e) {
        if (e !== null){
            e.preventDefault();
        }

        $('#signupOptions').slideUp();
        $('#signupForm').slideDown(function () {
            if (!md.mobile()) {
                $('#first_name').focus();
            }
        });
        this.initValidator();
    },
    doMailcheck: function (event) {
        var $emailInput = $(event.currentTarget);
        $emailInput.mailcheck(mailCheckObj.getDefaultOptions());
    },
    chooseDomainSuggestion: function (event) {
        analytics.trackGAEvent(false, "Account", "Clicked a 'mailcheck' suggestion");
        mailCheckObj.updateInputWithHint($(event.currentTarget).parent());

        return false;
    },
    enterSubmitAction: function (e) {
        if (e.which === 13) { // enter key
            this.signUpAndContinueAction(e);
        }
    },
    signUpAndContinueAction: function (e) {
        var that = this;

        e.preventDefault();
        grecaptcha.ready(function() {
            grecaptcha.execute(App.reCaptchaSiteKey, {action: 'signUpAndContinue'}).then(function(token) {
                require(
                    ['js/helpers/google_phone_number_handler'],
                    (phoneNumberHandler) => {
                        var formSignupHandle = $('#formSignup');
                        e.preventDefault();
                        if (!formSignupHandle.valid()) {
                            return;
                        }

                        var stageURI = urlHelpers.getStageURI();
                        if (stageURI === null) {
                            stageURI = 'login';
                        }

                        var stage = $("<input>")
                            .attr("type", "hidden")
                            .attr("name", "stage").val(stageURI);

                        var passconf = $("<input>")
                            .attr("type", "hidden")
                            .attr("name", "passconf").val($("#password_signup").val());

                        var gender = $("<input>")
                            .attr("type", "hidden")
                            .attr("name", "gender").val('unknown');

                        //strip mobile spaces... need to strip other stuff too though, and we don't want this to be visible to the user so maybe don't do it here?

                        var phoneStrip = ($('#raw_phone').val().replace(/\D/g, ''));

                        if (phoneStrip) {
                            phoneStrip = phoneNumberHandler.handleMobileParse(phoneStrip, that.businessModel.get('business_phoneNumberCountryCode'), 'db');
                        }

                        var phone = $("<input>")
                            .attr("type", "hidden")
                            .attr("name", "phone").val(phoneStrip);

                        formSignupHandle.append($(stage));
                        formSignupHandle.append($(passconf));
                        formSignupHandle.append($(gender));
                        formSignupHandle.append($(phone));

                        var data = formSignupHandle.serialize();
                        var dataJson = formSignupHandle.serializeArray();

                        //disable fields (need to un-do if returning to this form)
                        $('#formSignup input').prop('disabled', true); //works fine with jquery 1.9+

                        //disable button (need to un-do if returning to this form)
                        $('#btnContinue').removeClass('btn-success').addClass('btn-default disabled').find('.fa-chevron-circle-right').removeClass().addClass('fa fa-circle-o-notch fa-spin');

                        $.cookie('codeFailed', false);
                        $.cookie('resentSMS', false);

                        var facebookItem = _.findWhere(dataJson, {name: "registerType"});
                        var facebookRegister = (facebookItem !== undefined) ? facebookItem.value === "facebook" : false;

                        if (!facebookRegister) {
                            data += "&g_recaptcha_response=" + token;
                        }

                        //submit
                        that.userModel.register(data, that.businessModel, facebookRegister);
                    });
            });
        });
    },
    loginInstead: function (e) {
        e.preventDefault();
        $('#emailTakenModal').modal('hide');
        $('body').removeClass('modal-open');
        $('.modal-backdrop').remove();
        $('#formSignup')[0].reset();
        this.loginAction(null);
    },


    _handleLogin: function(data, email){
        var that = this;

        that.userModel.setLoginCookies(email, data.api_token, data.customer);
        //Make sure to redirect them back to confirmed once they are logged in.
        var hash_url = window.location.hash,
            query_index = hash_url.indexOf('?');
        if (query_index !== -1) {
            hash_url = '#confirm' + hash_url.substr(query_index);
        } else {
            hash_url = '#confirm';
        }

        if (data.customer.termsAcceptRequired) {
            if (hash_url.trim() === '') {
                hash_url = '#landing';
            }
            hash_url = '#acceptTerms/' + encodeURIComponent(hash_url);
        }
        window.location.hash = hash_url;
        //not sure what commenting below out could do .. but fixes the pay with credit card bug
        //('body').trigger('route:navigate_reload_from_state');

        $('#loginFailure').hide();
    },

    clickLoginBtn: function (e) {
        var that = this;
        var $password = $('#password'),
            $email = $('#email'),
            $loginBtn = $('#loginBtn');
        e.preventDefault();
        $('#loginFailure').hide();
        if (!this.initLoginValidator()) {
            return;
        }
        $loginBtn.removeClass('btn-success').addClass('btn-default disabled');
        config.fetchFromDom();

        var email = $email.val();
        var password = $password.val();

        this.userModel.login(email, password, function (data) {
            that._handleLogin.call(that, data, email);
        }).fail(function (xhr, textStatus, errorThrown) {
            xhr.errorHanlded = true;
            $('#loginFailure').show();
        }).always(function () {
            $loginBtn.addClass('btn-success').removeClass('btn-default disabled');
        });

    },

    loginFacebookAction: function(e){
        e.preventDefault();

        if(window.allowNormalFacebookFlow){
            this.startFacebookLogin(this);
        } else{
            if (_.contains(window.overrideNormalFacebookFlow, this.options.businessModel.get('id'))) {
                this.startFacebookLogin(this);
            }else{
                //this is for KIT-20738
                //immediately skip fb stuff to show modal
                $('#fbTempError').modal('show');
            }
        }
    },

    startFacebookLogin: function(){
        var that = this;
        var apiOptions = {scope: 'email'};

        if (this.businessModel.get('facebookReauthenticate')) {
            let nonce = this.businessModel.get('id') + Date.now(); // unique string to validate user login
            apiOptions = {scope: 'email', auth_type: 'reauthenticate', auth_nonce: nonce};
        }
        if (this.userModel.shouldUseFacebookRedirect()) {

            this.userModel.redirectToFacebook();

        } else if(this.stateModel.get('facebookActive')){
            this.userModel.getFacebookLibrary().login(function(response) {
                if(response.status === 'connected') {
                    that.resumeFacebookLogin(that);
                } else {
                    $('#fbErrorCode').text('ERR_SIGNUP_SDK_LOGIN_ABORT');
                    $('#fbError').modal('show');
                    analytics.trackGAEvent(false,'Error','Facebook error');
                }
            }, apiOptions);
        } else {
            $('#fbErrorCode').text('ERR_SIGNUP_SDK_INACTIVE');
            $('#fbError').modal('show');
            analytics.trackGAEvent(false, 'Error', 'Facebook error');
        }
    },

    resumeFacebookLogin: function(that) {
        $.cookie('kitomba.onlinebookings2.showFbSignup', 'true');

        $('body').addClass('loading');

        that.userModel.fblogin().done(function(data){
            $.cookie('kitomba.onlinebookings2.showFbSignup', 'false');
            data = JSON.parse(data);
            that._handleLogin.call(that, data, data.customer.clean_email);
        }).fail(function(error){
            error.errorHandled = true;
            window.location.reload();
        }).always(function () {
            $('body').removeClass('loading');
        });
    },

    checkLoginEnterKeyAction: function (e) {
        if (e.which === 13 && $('#loginBtn').hasClass('btn-success')) { // enter key
            this.clickLoginBtn(e);
        }
    },

    checkForgotEnterKeyAction: function (e) {
        if (e.which === 13 && $('#sendPwReminder').hasClass('btn-success')) { // enter key
            this.resetPasswordAction(e);
        }
    },

    resetPasswordAction: function (e) {
        e.preventDefault();
        $('#loginFailure').hide();
        var email = $('#reset_password_email').val(),
            hash_url = window.location.hash,
            query_index = hash_url.indexOf('?'),
            $forgotPWBtn = $('#sendPwReminderModal');
        $forgotPWBtn.removeClass('btn-success').addClass('btn-default disabled');

        if (query_index !== -1) {
            hash_url = '#confirm' + hash_url.substr(query_index);
        } else {
            hash_url = '#confirm';
        }

        var postData = {
            'email': email,
            'stage': encodeURIComponent(hash_url)
        };

        config.fetchFromDom();
        $.ajax({
            url: config.getAPIUrl() + "customer/" + config.getBusinessToken() + "/reset_password",
            type: "POST",
            data: postData,
            suppressErrors: true
        }).done(function () {
            $('#forgotPWFailure').hide();
            $('#forgotPassForm').fadeOut(function () {
                $('#pwResetSentTo').html(postData.email);
                $('#pwEmailSentMsg').show();
            });
        }).fail(function (xhr) {
            xhr.errorHandled = true;
            $('#forgotPWFailure').show();
        });
    },

    loginModalShowEvent: function (e) {
        $('#loginFailure, .loginModalMessage').hide();
    },

    pwModalShowEvent: function () {
        $('#sendPwReminderModal').addClass('btn-success').removeClass('btn-default disabled');
        $('#pwEmailSentMsg').hide();
        $('#forgotPassForm').show();
    },

    initLoginValidator: function () {
        $('#loginForm').validate({
            //  onfocusout: false,
            //  onkeyup: false,
            //  onclick: false,
            rules: {
                email: {
                    required: true,
                    email: true,
                    valid_email: /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
                },
                password: {
                    required: true,
                    minlength: 6
                }
            },
            messages: {
                email: {
                    required: "Please enter a valid email address",
                    email: "Please enter a valid email address",
                    valid_email: "Please enter a valid email address"
                },
                password: {
                    required: "Password is required",
                    minlength: "Password must be at least 6 characters"
                }
            },
            errorLabelContainer: "#loginValidationAnchor",
            wrapper: 'li',
            errorPlacement: function (error, element) {
                $('#loginValidationAnchor').append(error);
            }
        });

        return $('#loginForm').valid();
    },

    doPhoneValidation: function(evnt) {
        var value, isValid, $element = $(evnt.currentTarget);
        value = $element.val();

        if(value.trim() !== '') {
            require(
                ['js/helpers/google_phone_number_handler'],
                (phoneNumberHandler) => {
                    isValid = phoneNumberHandler.isValidMobile(value, this.businessModel.get('business_phoneNumberCountryCode'));
                    if (!isValid) {
                        $element.removeClass("checked");
                        $element.addClass("warning");
                        $element.siblings('i.notValid').show();
                        //   $('[data-toggle="tooltip"]').tooltip();
                        $element.siblings('i.valid').hide();
                    } else {
                        if ($element.hasClass('warning')) {
                            $element.addClass("checked");
                            $element.removeClass("warning");
                            $element.siblings('i.notValid').hide();
                            $element.siblings('i.valid').show();
                        }
                    }
                });
        } else {
            $element.removeClass("checked");
            $element.removeClass("warning");
            $element.siblings('i.notValid').hide();
        }

    }
});
