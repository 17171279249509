"use strict";
var Handlebars = require('handlebars');
var HandlebarsHelper = require('js/helpers/handlebarsHelpers');
var $ = require("jquery");
var _ = require("underscore");
var Backbone = require("backbone");
var analytics = require("./../helpers/analytics");
var loading = require("./../helpers/loadingScreen");
var BaseView = require("./baseView");
var confirmPageView = require("./confirmPageView");
var BookingModel = require("./../models/bookingModel");
var UrlHelper = require("./../helpers/urlHelpers");
var config = require("./../models/configModel");
var moment = require("moment");
var PaymentFactory = require('../models/paymentFactory');
var Rollbar = require('../rollbar');

Backbone.$ = $;

var confirmTemplate = Handlebars.compile(require('./../../templates/pageConfirmPayment.hbs').default);
var confirmedTemplate = Handlebars.compile(require('./../../templates/pageConfirmed.hbs').default);

/**
 *
 * @type {*}
 * @property {BookingModel} model
 */
module.exports = confirmPageView.extend({
    page: "confirmPayment",
    pageTitle: "Confirming payment",
    pageTitleBooked: "You're booked!",
    template: confirmTemplate,
    confirmedTemplate: confirmedTemplate,
    events: {},

    initialize: function (options) {
        var url, method, that = this;
        BaseView.prototype.initialize(options); //call parent constructor

        this.customer_kid = options.customer_kid;
        this.appt_hash = options.appt_hash;

        if (options.reschedule === "true") {
            url = config.getAPIUrl() + "booking/" + config.getBusinessToken() + "/reschedule";
            method = "PUT";
        } else {
            url = config.getAPIUrl() + "booking/" + config.getBusinessToken();
            method = "POST";
        }

        var paymentDriver = PaymentFactory.getPaymentModel(this.businessModel);

        paymentDriver.finalise(url, method, this.customer_kid, this.appt_hash).done(function(data){
            that.cleanUri();
            if (data.success === true) {
                $("#booking_id").html(data.booking_id);

                that.model = new BookingModel({
                    customerId: options.userModel.id,
                    allowDoubleBooking: true,
                    suppressEmail: false,
                    services: data.appointmentData,
                    stateModel: options.stateModel
                });

                // So you can do another booking with no deposit without having to reload page to get this updated...
                options.userModel.set('cardonfile', data.cardonfile);

                analytics.trackGAEvent(true, "Bookings", "Appointment booked", data.withNotes, parseInt(data.paymentDetails.total));

                let paymentGatewayUsed = paymentDriver.driverName;
                let depositAmountPaid = parseInt(data.paymentDetails.deposit);

                analytics.trackGAEvent(true, "Bookings", "Deposit taken", "Gateway: " + paymentGatewayUsed, depositAmountPaid);
                analytics.trackFBEvent('Schedule');

                that.businessModel.set("shouldDisplayPaymentReceiptEmailNotification", true);

                that.renderConfirmed();
            } else {
                var errorMessage;

                if (typeof data !== "undefined"
                    && typeof data.appointment_info !== "undefined"
                    && typeof data.appointment_info.stateModel !== "undefined"
                ) {

                    that.stateModel.set("time", data.appointment_info.stateModel.time);
                    that.stateModel.get("services").add(data.appointment_info.stateModel.services);
                    that.stateModel.get("packageDetails").add(data.appointment_info.stateModel.packageDetails);
                    that.stateModel.set("unavailable", data.appointment_info.stateModel.unavailable);
                    that.stateModel.set("facebookActive", data.appointment_info.stateModel.facebookActive);
                    that.stateModel.set("booking_photos", data.appointment_info.stateModel.booking_photos);

                    if (typeof data.paymentGatewayError !== "undefined") {
                        analytics.trackGAEvent(true, "Bookings", "Deposit failed", "Gateway: "+paymentDriver.driverName);

                        errorMessage = data.paymentGatewayError;

                        if (!errorMessage) {
                            errorMessage = "There was an unexpected error while attempting to process your transaction";
                            console.warn("Empty payment gateway error received for gateway " + paymentDriver.driverName);
                        }
                        that.showError("Payment error", errorMessage, data.paymentGatewaySubError);
                    } else {
                        analytics.trackGAEvent(true, "Bookings", "Deposit aborted", "Gateway: "+paymentDriver.driverName);
                    }

                    $("body").trigger("route:navigate_replace", that.stateModel.getURLHash("confirm"));
                } else if (_.has(data, "paymentGatewayError")) {
                    analytics.trackGAEvent(true, "Bookings", "Deposit failed", "Gateway: "+paymentDriver.driverName);

                    if (that.shouldLetUserRetry(data)) {
                        that.returnToConfirmBooking();
                    } else {
                        errorMessage = data.paymentGatewayError;

                        if (!errorMessage) {
                            errorMessage = "There was an unexpected error while attempting to process your transaction";
                            console.warn("Empty payment gateway error received for gateway " + paymentDriver.driverName);
                        }
                        that.showError("Payment error", errorMessage, data.paymentGatewaySubError);
                    }
                } else if (_.has(data, "error")) {
                    analytics.trackGAEvent(true, "Bookings", "Booking failed", "Double booking");

                    that.showError("Booking error", 'Your selections are no longer available.');
                } else {
                    analytics.trackGAEvent(true, "Bookings", "Booking failed", "unknown");

                    that.showError("Booking error", 'There was an unexpected error while attempting to complete your booking');
                    Rollbar.error("Unexpected error while processing payment response", data);
                }
            }
        }).error(function (jqXhr, textStatus, errorThrown) {
            analytics.trackGAEvent(true, "Bookings", "Booking failed", "unknown");

            that.showError("Booking error", 'There was an unexpected error while attempting to complete your booking');
            Rollbar.error("Unexpected error while sending payment finalize request", { textStatus: textStatus, errorThrown: errorThrown });

            jqXhr.errorHandled = true;
        });

        this.servicesCollection = options.servicesCollection;
        this.staffCollection = options.staffCollection;
        this.packagesCollection = options.packagesCollection;
        this.userModel = options.userModel;
        this.stateModel = options.stateModel;
    },

    showError: function (title, message, subMessage) {
        $("body").trigger("route:navigate_reload_with_error", {
            title: title,
            message: message,
            subMessage: subMessage ? subMessage : false,
            targetPage: 'services'
        });
    },

    returnToConfirmBooking: function() {
        $("body").trigger("route:navigate_replace",'cancelPayment');
    },

    render: function () {
        this.$el.html(this.template());
        BaseView.prototype.render(this);

        loading.until(false);

        this.setPageTitle(this.pageTitle);
    },

    renderConfirmed: function () {
        var templateData = this.getTemplateData();

        templateData.shouldDisplayPaymentReceiptEmailNotification = this.businessModel.get('shouldDisplayPaymentReceiptEmailNotification');

        templateData.appointmentDisplayDate = moment(this.model.getFirstServiceStartDate()).format("h:mma [on] dddd D MMMM"); //4:00pm on Monday 12 April

        this.$el.html(this.confirmedTemplate(templateData));
        BaseView.prototype.render(this);

        this.setPageTitle(this.pageTitleBooked);
    },

    shouldLetUserRetry: function(data) {
        if (data.paymentGatewayError === 'CANCELLED') {//windcave cancel status
            return true;
        }
        return false;
    },

    cleanUri: function() {
        var uri = window.location.toString();
        if (uri.indexOf("?") > 0) {
            var clean_uri = uri.substring(0, uri.indexOf("?"));
            window.history.replaceState({}, document.title, clean_uri);
        }
    }

});
