"use strict";
var $ = require('jquery'),
    _ = require('underscore'),
    analytics = require('./../helpers/analytics'),
    loading = require('./../helpers/loadingScreen'),
    UrlHelper = require('./../helpers/urlHelpers'),
    VoucherSale = require('./voucherSale'),
    Backbone = require('backbone');
var config = require('./../../js/models/configModel');
const BookingErrorModal = require("./BookingErrorModal");

Backbone.$ = $;
/* globals paypal, grecaptcha, App */

module.exports = function(businessModel) {
    return {
        driverName: "PayPal",

        getPaymentOptionIcons: function() {
          return 'https://www.paypalobjects.com/webstatic/en_US/i/buttons/cc-badges-ppppcmcvdam.png';
        },

        setupDepositButton: function(res, confirmPageView, clickHandler, button){
            button.remove();

            confirmPageView.stateModel.set('paypal_button_exists', true);
            if(typeof window.paypal === 'undefined'){
                document.body.addEventListener('PayPalScriptReady', this.setUpDepositECButton.bind(this));
            }
            else {
                this.setUpDepositECButton(confirmPageView, clickHandler);
            }
        },

        setupVoucherButton: function(voucherPageView, clickHandler, button){
            button.remove();
            var that = this;
            voucherPageView.stateModel.set('paypal_button_exists', true);
            if(typeof window.paypal === 'undefined'){
                document.body.addEventListener('PayPalScriptReady',
                  function() {
                        that.setUpVoucherEcButton(clickHandler);
                    });
            } else {
                this.setUpVoucherEcButton(clickHandler);
            }
        },

        setUpDepositECButton: function(confirmPageView, clickHandler) {
            window.paypal.checkout.setup($('#paypalMerchant').data('id'), {
                environment: confirmPageView.businessModel.get('paypal').environment,
                container: 'confirmPageForm',
                click: clickHandler
            });
        },

        setUpVoucherEcButton: function(clickHandler) {
            window.paypal.checkout.setup($('#paypalMerchant').data('id'), {
                environment: businessModel.get('paypal').environment,
                container: 'voucherCreateForm',
                click: clickHandler
            });
        },

        payDeposit: function (bookingModel) {

            $('#preBookingContent, .footer').remove();
            $('#reloadPostPayPal').show();

            //if there are notes we need to save them now into appointment staging on the server
            if (bookingModel.hasNotes()) {
                loading.until(bookingModel.saveNotesByAppointmentHash(bookingModel.attributes.apptHash)).done(startPayPalFlow);
            } else {
                startPayPalFlow();
            }

            function startPayPalFlow() {
                paypal.checkout.reset(); //in-case the user has closed the checkout window
                paypal.checkout.initXO();
                paypal.checkout.startFlow(bookingModel.attributes.token);
            }
        },

        payVoucher: function(voucherSaleModel) {
            paypal.checkout.reset(); //in-case the user has closed the checkout window
            paypal.checkout.initXO();
            var callback = function() {$('body').trigger('route:navigate_replace', 'voucher');};
            if (!voucherSaleModel) {
                paypal.checkout.closeFlow();
                return new BookingErrorModal({
                    title: 'Payment Error',
                    subTitle: 'We could not complete the transaction',
                    message: "Invalid voucher sale model provided.",
                    closeCallback: callback
                });
            }

            if(voucherSaleModel.showPaymentGatewayError === true) {
                paypal.checkout.closeFlow();
                // This is mostly copied from router.js, could be refactored
                return new BookingErrorModal({
                    title: 'Payment Error',
                    subTitle: 'We could not complete the transaction',
                    message: voucherSaleModel.paymentGatewayError === undefined ? "" : voucherSaleModel.paymentGatewayError,
                    closeCallback: callback
                });
            }

            paypal.checkout.startFlow(voucherSaleModel.postParameters['ecToken']);
        },

        createVoucherSale: function(purchaseHash){
            return new VoucherSale({
                purchaseHash: purchaseHash,
                postParameters : {token: UrlHelper.getQueryParam('token'), payer_id: UrlHelper.getQueryParam('PayerID')}
            });
        },


        finalise: function(url, method, customer_kid, appt_hash){

            grecaptcha.ready(function() {
                grecaptcha.execute(App.reCaptchaSiteKey, {action: 'kitombaPayButtonClick'}).then(function(token) {
                    return $.ajax({
                        url: url,
                        method: method,
                        dataType: "json",
                        data: {
                            driver: "paypal",
                            customer_kid: customer_kid,
                            appt_hash: appt_hash,
                            token: UrlHelper.getQueryParam("token"),
                            payer_id: UrlHelper.getQueryParam("PayerID"),
                            g_recaptcha_response: token,
                        }
                    });
                });
            });
        }
    }
};
